@import "./easycalvariables.scss";
@import "./bootstrap/scss/bootstrap.scss";

/* navbar */

.navbar-toggler-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

/* all below subject to change*/
.display-title {
    font-size: 2.2rem;
    font-weight: 300;
    line-height: 1.1;
	margin-bottom: 1r
}

A.delx {
	color: red;
	text-decoration: none;
}

A.delx:hover {
	color: #f95f55;
}

#layoutArea {
	margin: 24px 0 20px 0px;
}

#contentArea {
	margin: 0 0 0 0px;
}
